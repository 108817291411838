import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import './NavigationMenu.css';
import { MENU_OPTIONS } from "../../utils/constants/NavigationOptions";
import * as c from "../../utils/constants/constants";
import * as msg from "../../utils/constants/messages";
import { forceLogout } from "../../commonFunctions/forceLogout";
import { getUserProfileData } from "../../redux/userprofile/actions/actions";
import { getGnrlSettingsActions } from "../../redux/generalSettings/actions/actionCreators";
import { connect } from 'react-redux';
import { GetFilteredMenuData, GetSelectedMenus, ModifyMenuData } from './helper';
import { useLocation } from "react-router-dom";
import ReadablLogo from "./ReadablLogo"
import ProfileMenu from './ProfileMenu/ProfileMenu';
import SubMenus from './SubMenus/SubMenus';
import MainMenu from './MainMenu/MainMenu';
import { DeepClone } from 'commonFunctions/commonFunctions';

const NavigationMenu = (props) => {
    const location = useLocation();
    const [options, updateMenuOptions] = useState([]);
    const [subMenus, updateSubMenus] = useState([]);
    const [selectedMenu, updateSelectedMenu] = useState("");
    const [isDuplicate, updateDuplicate] = useState(null);

    useEffect(() => {
        if (location) {
            updateSelectedMenu(location.pathname);
            const Params = new URLSearchParams(window.location.search);
            updateDuplicate(Boolean(Params.get('duplicate')));
        }
    }, [location])

    useEffect(() => {
        if (!localStorage.getItem(c.USER_NAME)) {
            props.getUserProfileData(c.ROOT_URL + "/getUserProfile");
        } else {
            getMenuOptions(localStorage.getItem(c.USER_ROLE))
        }
        return () => {
            window.removeEventListener("click", () => { })
        }
    }, []);

    useEffect(() => {
        if (subMenus?.length) {
            window.addEventListener("click", e => {
                let menuElement = document.getElementById("menu-list-container");
                if (!menuElement?.contains(e.target)) {
                    updateSubMenus([])
                }
            });
        } else {
            window.removeEventListener("click", () => { })
        }
    }, [subMenus]);

    useEffect(() => {
        if (props.userprofiledata.hasError === 401) {
            forceLogout();
        } else if (props.userprofiledata.hasError)
            window.alert(msg.api_error);
        if (props.userprofiledata.userprofiledata?.data?.length) {
            getMenuOptions(props.userprofiledata.userprofiledata.data[0].role);
            localStorage.setItem(c.USER_NAME, props.userprofiledata.userprofiledata.data[0].name);
        }
            
    }, [props.userprofiledata?.userprofiledata?.data?.[0]?.name])


    useEffect(() => {
        let menus = selectedMenu.split("/").filter(m => m);
        props.updateSelectedMenu(menus);

    }, [selectedMenu]);

    const getMenuOptions = (role) => {
        let menuOptions = MENU_OPTIONS.map(obj => DeepClone(obj));
        let menuData = GetFilteredMenuData(menuOptions, role);
        ModifyMenuData(menuData);
        updateMenuOptions(menuData);
    }

    const handleMouseEnter = (event, menu) => {
        if (menu?.children?.length) {
            updateSubMenus([
                {
                    anchorEl: event.currentTarget,
                    children: menu.children,
                    key: menu.value,
                },
            ]);
        }
    };

    const handleSubMenuMouseEnter = (event, menu) => {
        let tempSubMenus = [...subMenus];
        tempSubMenus.splice(menu.level, tempSubMenus.length - menu.level)
        if (menu.children?.length) {
            tempSubMenus.push({
                anchorEl: event.currentTarget,
                children: menu.children,
                key: menu.value,
            });
            
        }
        updateSubMenus(tempSubMenus);
    };

    const handleMenuSelect = (e, menu) => {
        if (menu.children?.length && menu.hasOwnPath || !menu.hasOwnProperty("children")) {
            let selected = GetSelectedMenus(menu.value)
            props.updateSelectedMenu(selected.split("/").filter(m => m));
            updateSelectedMenu(selected);
            updateSubMenus([]);
        } else e.preventDefault();

    }

    const handleClose = (event) => {
        updateSubMenus([])
    };

    return (
        <>
            <div style={{ padding: isDuplicate ? "5px 10px" : "unset" }} className={"navigation-menu-container " + (localStorage.getItem(c.SETTINGS) && JSON.parse(localStorage.getItem(c.SETTINGS)).Environment === "mh-test" ? "navigation-menu-container-blue" : "")}>
                <Grid className="left-grid" >
                    <ReadablLogo />
                    <div className="navbar-menu-list" id="menu-list-container">
                        {!isDuplicate ? <MainMenu
                            options={options}
                            subMenus={subMenus}
                            selectedMenu={selectedMenu}
                            handleClose={handleClose}
                            handleMouseEnter={handleMouseEnter}
                            handleMenuSelect={handleMenuSelect}
                        /> : null}
                    </div>
                </Grid>
                <ProfileMenu
                    isDuplicate={isDuplicate}
                    profileData={props?.userprofiledata?.userprofiledata?.data[0] || {name: localStorage.getItem(c.USER_NAME)}}
                />
                <SubMenus
                    subMenus={subMenus}
                    handleClose={handleClose}
                    selectedMenu={selectedMenu}
                    handleMenuSelect={handleMenuSelect}
                    handleSubMenuMouseEnter={handleSubMenuMouseEnter}
                />
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        userprofiledata: state.userprofiledata,
        settings: state.settings?.settings || {}
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUserProfileData: (url) => dispatch(getUserProfileData(url)),
        updateGeneralSettings: url => dispatch(getGnrlSettingsActions.getGnrlSettingsActionsSuccess(url)),
        updateSelectedMenu: url => dispatch(getGnrlSettingsActions.updateSelectedMenu(url))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMenu);
