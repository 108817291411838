/*Common */
export const server_error = "Server error. Please try again";
export const no_data = "No data to display.";
/* Doc details screen */
export const file_lock_msg = "This document is being edited by another user or users.";
export const stat_tooltip = "Looks like you have made some changes to the document. Please save or discard them.";
export const api_error = "Error occured. Request failed.";
export const rate_limiter = "Too many requests. Please try after sometime.";
export const ext_info = "Information will be shown here once the classification and extraction is completed.";
export const data_save = "Data saved successfully.";
export const data_discard = "Your changes will be discarded.";
export const upload_mandatory = "Following fields are mandatory for this document to be uploaded.";
export const block_edits = " Any edits to the document is restricted!";
export const doc_upload = "This document will be uploaded to EHR";
export const doc_upload_retry = "Only orders that failed OnBase upload will be re-uploaded.";
export const doc_upload_retry_file_not_found = "The document trying to upload was not available in the G Drive.";
export const doc_uploading = "This document is being uploaded to EHR." + block_edits;
export const doc_uploaded = "This document has been uploaded to EHR." + block_edits;
export const doc_move = "This document is being moved to completed status." + block_edits;
export const doc_cmp = "This document has been completed." + block_edits;
export const file_duplication_prompt = "This warning will no more appear on this document";
export const doc_cmp_prompt = "This document will be moved to the xCompleted folder. Completed documents will not allow any edits.";
export const mark_cmp_with_upload = "This document will not be uploaded but marked as completed. Completed documents will not allow any edits.";
export const mark_cmp_with_upload_failed = "This document has failed OnBase upload. Do you want to mark it as completed? Completed documents will not allow any edits.";
export const mark_cmp_without_upload = "This document does not have a patient match. Do you want to mark this as completed? Completed documents will not allow any edits.";
export const flag_notes_missing_message = "Flag notes was not filled. Do you want to proceed without flag notes.";
export const spam_validation_message = "Spam flag accept only valid fax numbers.";
export const spam_warning_message = "Selection of spam flag will disable all other flags.";
export const order_completion_status = "Please select order completion status.";
export const spam_mark_cmp_warning = "This document will be moved to the SPAM folders. Completed documents will not allow any edits.";
export const docSplitStuckWarning = "This document was stuck during splitting for more than 30 minutes.";
export const not_available_warning_message = "You don't have permission to view this document.";
export const partial_split_warning_message = "This document was not completely split.\nDo you want to continue with partial document split?";
export const coverpage_inprogress = "Fax cover page removal is in progress. Edits to this document is not allowed.";
export const coverpage_completed = "Fax cover page has been removed from this document.";
export const coverpage_failed = "Fax cover page removal is failed";
export const faxcoverpage_confirmation = doc_upload + " and the fax cover page will be removed."

/*Dashboard */
export const no_chart_data = "No data to display in the selected interval.";
/* Configuration */
export const mant_flg_field = "Following fields are mandatory to create new flag";
export const mant_loc_field = "Following fields are mandatory to create new location";
export const conf_flg_msg = "Flag details are not saved. Are you sure want close?";
export const conf_loc_msg = "Location details are not saved. Are you sure want close?";
export const nochange_msg = "No changes were made. Do you want to continue?";
export const sus_flg_msg = "New flag created successfully.";
export const sus_loc_msg = "Request sent successfully.";
export const update_msg = "Updated successfully.";
export const err_msg = "Something went wrong.";
