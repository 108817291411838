import { DeepClone } from "commonFunctions/commonFunctions";
import { GetRoutingData, } from "components/NavigationMenu/helper";
import NavigationMenu from "components/NavigationMenu/NavigationMenu";
import NotFound from "components/NotFound/NotFound";
import InfoScreen from "pages/InfoScreen/InfoScreen";
import React, { useEffect, useState, lazy } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import { MENU_OPTIONS } from "utils/constants/NavigationOptions";
import * as c from "../utils/constants/constants";

const Login = lazy(() => import('../pages/Login/Login'));
const SSORedirect = lazy(() => import('../pages/SSORedirect/SSORedirect'));
const SSOLanding = lazy(() => import('../pages/SSOLanding/SSOLanding'));
const CheckIdleTime = lazy(() => import('../container/CheckIdleTime'));
const PatientCreate = lazy(() => import('../pages/PatientCreate/PatientCreate'));

const patientPopupVisiblePages = [c.PAGE_URLS[c.PAGE_DASHBOARD], c.PAGE_URLS[c.PAGE_DOC_MGMT],
c.PAGE_URLS[c.PAGE_USER_MGMT], c.PAGE_URLS[c.PAGE_MANUAL_REVIEW],
c.PAGE_URLS[c.PAGE_AUDIT_LOG], c.PAGE_URLS[c.PAGE_USER_PROFILE],
c.PAGE_URLS[c.PAGE_REPORTS], c.PAGE_URLS[c.PAGE_PATIENT]]

const Entry = (props) => {
  const location = useLocation(null);
  const [currentPage, updateCurrentPage] = useState("");
  const [currentLocation, updateCurrentLocation] = useState("");
  const [routes, updateRoutes] = useState([]);

  useEffect(() => {
    let menuOptions = MENU_OPTIONS.map(obj => DeepClone(obj));
    let routingData = GetRoutingData(menuOptions);
    updateRoutes(routingData);
  }, [localStorage.getItem(c.USER_ROLE)])

  useEffect(() => {
    updateCurrentLocation(window.location?.pathname);
  }, [location]);
  return (

    <div className="page-main-container">
      
      {patientPopupVisiblePages.find(m => currentLocation.includes(m)) ? <PatientCreate /> : null}
      <Switch>
        <Route
          exact
          path={`/${c.PAGE_URLS[c.PAGE_LOGIN]}`}
          render={(props) => (
            c.SSO === 'true' ?
              <SSOLanding {...props} />
              :
              <Login {...props} />
          )}
        />
        <Route
          exact
          path={`/${c.PAGE_URLS[c.SSO_REDIRECT]}`}
          render={(props) => (
            <SSORedirect {...props} />
          )}
        />
        <Route
          exact
          path={`/${c.PAGE_URLS[c.SSO_LANDING]}`}
          render={(props) => ( 
            <SSOLanding {...props} />
          )}
        />
        <Route
          exact
          path={`/${c.PAGE_URLS[c.PAGE_INFO]}`}
          render={(props) => (
            <InfoScreen {...props} />
          )}
        />
        {localStorage.getItem(c.ACCESS_TOKEN) ?
          <React.Fragment>
            {
              routes.map((m, i) => {
                const Component = m.component;
                return (<Route
                  exact
                  path={m.path}
                  key={i}
                  render={(props) => {
                    return (
                      <>
                      {m.hideNavigationMenu == true ? null : <NavigationMenu />}
                        <Component
                          {...props}
                        />
                      </>
                      )
                  }}
                />)
              })
            }
            {localStorage.getItem(c.SETTINGS) && <CheckIdleTime />}
          </React.Fragment> : null}

        {c.SSO === 'true' ?
          <Redirect to={`/${c.PAGE_URLS[c.SSO_REDIRECT]}`}
            render={(props) => (
              <SSORedirect {...props} />
            )}
          /> : <Redirect to={`/${c.PAGE_URLS[c.PAGE_LOGIN]}`} render={(props) => (<Login {...props} />)}
          />
        }
      </Switch>
    </div>
  );
}

export default Entry;
